import React from 'react';
import red from "../images/red.png";
import red2 from "../images/red2.png";
import arrows from "../images/arrows.png";
import tennis from "../images/tennis.png";
import hand from "../images/hand.png";

export default function Red() {
  return (
    <div className="container mx-auto py-10 w-5/6 md:w-4/6">
      <a href="/#projects" className="text-3xl mr-auto hover:text-blue-300 text-center md:text-left pb-10">⟵</a>
      <h1 className="text-center text-3xl font-bold mb-10">Meet Red!</h1>
      <img src={red} alt='Cartoon character with a tennis racket for a head.' className='w-5/6 mb-8 mx-auto'></img>
      <p className="w-5/6 mx-auto mb-8 md:text-left">
        Red is a character I created, when tasked to create an Olympic-themed character.
        <br></br>
        <br></br>
        She originally started as evolving sketches, which then led to creating a 3D model of her in Maya.
      </p>
      <img src={red2} alt='Full render of the cartoon character, uncoloured' className='w-5/6 mx-auto mb-8'></img>
      <p className='w-5/6 mx-auto mb-8'>Here, she has been textured, coloured and brought to life:</p>
      <img src={tennis} className='w-5/6 mx-auto mb-8' alt='Cartoon character fully textured and coloured standing on a tennis court'></img>
      <img src={hand} className='w-5/6 mb-8 mx-auto' alt='A glass hand from the cartoon character'></img>
      <img src={arrows} className='w-5/6 mx-auto' alt='Golden arrows on the back of a cartoon character'></img>

    </div>
  );
}