import canvasSketch from "./components/images/canvas2.png";
import d3pv from "./components/images/d3pv2.png";
import red from "./components/images/red.png";
import melv from "./components/images/disco.png";
import raytracer from "./components/images/photon2.png"
import snake from "./components/images/snake.png";

export const projects = [
  {
    title: "Generative Art",
    subtitle: "JavaScript, HTML, , p5.js, Canvas-Sketch",
    description:
      "Upon realising I needed some new artwork for my flat, I decided to code my own!  ",
    image: canvasSketch,
    link: "./sketches",
  },
  {
    title: "D3Plot Viewer",
    subtitle: "JavaScript, CSS, HTML",
    description:
      "A 3D GLB model viewer, owned by Oasys LS-DYNA. Currently Lead Developer.",
    image: d3pv,
    link: "./d3pv",
  },
  {
    title: "Meet Red!",
    subtitle: "Maya",
    description:
      "An olympic-themed characted, named Red. Designed, created and coloured by me.",
    image: red,
    link: "/red",
  },
  {
    title: "Disco Melv",
    subtitle: "Maya",
    description:
      "This is Melvin - he likes Disco. An animation by me.",
    image: melv,
    link: "/disco",
  },
  {
    title: "Raytracer/Rasteriser",
    subtitle: "Matlab, C++",
    description:
      "Built a graphics raytracer and rasteriser. Implemented photon mapping and also animation.",
    image: raytracer,
    link: "/raytracer",
  },
  {
    title: "Snake",
    subtitle: "Python",
    description:
      "Implemented the classic game Snake and created step-by-step instructions for Comp-Sci students to follow along.",
    image: snake,
    link: "/snake",
  },
];


export const skills = ["JavaScript", "HTML5", "CSS3", "React", "Tailwind CSS", "Babylon.js", "Python", "Express.js", "Node.js", "p5.js"];