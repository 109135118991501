import React, { useEffect } from "react";
import "./css/background.css";

export default function Background() {
    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = window.scrollY;
        const zoomLevel = 1 + scrollTop * 0.0005; // Adjust the multiplier to control zoom speed
        document.documentElement.style.setProperty('--zoom-level', zoomLevel);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
  return <div id='background' />;
}