import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Background from './components/background';
import Navbar from './components/navbar';
import About from './components/pages/about';
import Home from './components/home';
import ScrollToTop from './components/extra';
import Sketches from './components/pages/sketches';
import D3pv from './components/pages/d3pv';
import ContactForm from './components/pages/contact';
import Red from './components/pages/red';
import Disco from './components/pages/disco';
import Raytracer from './components/pages/raytracer';
import Snake from './components/pages/snake';

process.env.CI = false;

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <main className="text-gray-600 body-font font-mono h-screen">
        <Background />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/sketches" element={<Sketches />} />
          <Route path="/d3pv" element={<D3pv />} />
          <Route path="/red" element={<Red />} />
          <Route path="/disco" element={<Disco />} />
          <Route path="/raytracer" element={<Raytracer />} />
          <Route path="/snake" element={<Snake />} />
        </Routes>
      </main>
    </Router>
  );
}