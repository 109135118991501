import React from "react";
import me from "./images/me.png";
import './css/hero.css'

export default function Hero() {
  return (
    <section id="hero">

      <div className="heroLeft container mx-auto flex px-10 md:flex-row flex-col items-center md:h-90v pt-40 md:pt-0">
        <div className="animate__animated animate__shakeX lg:flex-grow md:w-90 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center pb-20 md:pb-0">
          <h1 className="animate__animated animate__shakeX title-font sm:text-4xl text-3xl mb-1 border-b-2 border-gray-400 w-100 pb-2.5 font-bold">
            Hi! I'm Holly.
          </h1>

          <h2 className="title-font sm:text-xl text-2xl mb-4 font-medium border-t-2 border-gray-400 w-80 p-2.5">
            Software Developer.
            <br></br>
            (...with a creative touch)
          </h2>

          <div className="flex justify-center">
            <a
              href="/about"
              className="inline-flex text-[color:var(--primary-color)] bg-[color:var(--background-color)] border-0 py-2 px-6 focus:outline-none hover:bg-[color:var(--blue-color)] hover:text-[color:var(--background-color)] transition-colors duration-300 rounded text-lg">
              Learn More About Me
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-[color:var(--primary-color)] bg-[color:var(--background-color)] border-0 py-2 px-6 focus:outline-none hover:bg-[color:var(--blue-color)] transition-colors duration-300 hover:text-white rounded text-lg">
              See My Past Work
            </a>
          </div>
        </div>

        <div id="heroImageContainer">
          <svg id="heroFrame" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <clipPath id="heroClipPath">
              <path fill="#F2F4F8" transform="scale(3, 3.5) translate(60 70)" d="M38.8,-23.5C47.8,-6.8,51,12.3,43.5,27.4C36.1,42.6,18,53.9,-0.4,54.2C-18.9,54.4,-37.9,43.6,-49.2,26.2C-60.6,8.7,-64.4,-15.3,-54.9,-32.3C-45.4,-49.2,-22.7,-59.1,-3.9,-56.8C14.9,-54.6,29.8,-40.2,38.8,-23.5Z" />
            </clipPath>
          </svg>

          <div id="heroImageShadow" className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img id="heroImage" alt="holly-millea" src={me} />
          </div>

        </div>
      </div>
    </section>
  );
}