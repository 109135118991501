import { CodeBracketIcon } from "@heroicons/react/24/solid";
import React from "react";
import { projects } from "../data";

export default function Projects() {
  return (
    <section id="projects" className="text-gray-600 body-font">
      <div className="container px-5 py-10 mx-auto text-center lg:px-40">
        <div className="flex flex-col w-full mb-20">
          <CodeBracketIcon className="mx-auto inline-block w-10 mb-4" />
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
            Projects of Mine
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Here is a sample of some of my previous work, including some web projects, graphics work and other creative bits :)
            <br></br>
            <br></br>
            This website (my portfolio) was also created by me, using React, Tailwind CSS and HTML.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {projects.map((project) => (
            <a
              href={project.link}
              key={project.image}
              className="sm:w-1/2 w-full p-4">
              <div className="min-h-80 relative w-full">
                <img
                  alt="gallery"
                  className="min-h-full max-h-full absolute inset-0 object-cover object-center min-w-full max-w-full rounded"
                  src={project.image}
                />
                <div className="px-8 py-10 relative z-8 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100 rounded h-full min-h-80">
                  <h2 className="tracking-widest text-sm title-font font-medium text-[color:var(--blue-color)] mb-1">
                    {project.subtitle}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-[color:var(--background-color)] mb-3">
                    {project.title}
                  </h1>
                  <p className="leading-relaxed">{project.description}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
}