import React from 'react';
import CV from '../extras/HM-CV.pdf';

export default function About() {
  return (
    <div className="container mx-auto py-20 w-4/6">
      <a href="/" className="text-3xl text-center hover:text-blue-300 mr-auto md:text-left pb-10 md:pb-0">⟵</a>
      <h1 className="text-3xl text-center font-bold mb-4">A little bit about me...</h1>
      <p className="mb-8 text-left">
        I'm Holly and I am Software Developer with a keen interest in graphics, web and all things creative!
        <br /><br />
        I discovered coding during my Mathematics degree and went on to explore different avenues of creative development during my Masters.
        <br /><br />
        Since then, I curated and taught my own unit at The University of Bristol (teaching Python through game development) and have become a full-time Software Developer (working on a web app utilising JavaScript's game engine Babylon.js).
        <br /><br />
        You can find more detail about this in my <a href={CV} target="_blank" rel="noreferrer" className='hover:text-blue-300 font-bold underline'>CV</a> :)
      </p>
    </div>
  );
}