import React from 'react';
import d3pv from '../images/d3pv2.png';
import d3pv3 from '../images/d3pv5.png';
import d3pv4 from '../images/d3pv4.png';
import video from '../images/d3pv.mp4';

export default function D3pv() {
  return (
    <div className="container mx-auto py-10 w-5/6 md:w-5/6 flex flex-col gap-10">
      <a href="/#projects" className="text-3xl mr-auto hover:text-blue-300 text-center md:text-left">⟵</a>
      <h1 className="text-3xl text-center font-bold">D3Plot Viewer</h1>
      <div className="flex flex-col items-center gap-10">
        <div className='flex flex-col md:flex-row gap-10 items-center'>
          <img src={d3pv} alt='An example model being shown in D3Plot Viewer' className='w-full md:w-3/6' />
          <p className="mb-8 md:text-left">
            A 3D model viewer (GLB/GLTf), owned by Oasys LS-DYNA. Used for engineers to communicate results and models to clients.
            <br /><br />
            Product can be found <a href='https://www.oasys-software.com/dyna/software/d3plotviewer/' target='_blank' rel='noreferrer' className='font-bold underline'>here</a>.
          </p>
        </div>
        <div className='hidden md:flex flex-col md:flex-row gap-14 items-center'>
          <p className="mb-8 md:text-left">
            Lead developer for the multiple windows enhancement, allowing users to interact with multiple models simultaneously.
            <br /><br />
            STACK: Babylon.js, JavaScript, CSS, HTML
          </p>
          <img src={d3pv3} alt='A screenshot of D3Plot Viewer containing multiple windows' className='w-4/6 md:w-3/6' />
        </div>
        <div className='md:hidden flex flex-col md:flex-row gap-14 items-center'>
          <img src={d3pv3} alt='A screenshot of D3Plot Viewer containing multiple windows' className='w-full md:w-3/6' />
          <p className="mb-8 md:text-left">
            Lead developer for the multiple windows enhancement, allowing users to interact with multiple models simultaneously.
            <br /><br />
            STACK: Babylon.js, JavaScript, CSS, HTML
          </p>
        </div>
        <div className='flex flex-col md:flex-row gap-14 items-center'>
          <img src={d3pv4} alt='A screenshot of an older version of D3Plot Viewer' className='w-full md:w-3/6' />
          <p className="mb-8 md:text-left">
            Single-handedly implemented the new UI designs for the entire software.
            <br /><br />
            Previous UI can be seen here.
          </p>
        </div>
        <p>Here is a little video demonstration of features of the viewer (in which I have worked on)...</p>
        <video
          src={video}
          className='w-full md:w-5/6'
          controls
          preload="metadata"
        />
      </div>
    </div>
  );
}