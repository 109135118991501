import React, { useEffect } from "react";
import "./css/navbar.css";
import { Link } from "react-router-dom";

export default function Navbar() {
  useEffect(() => {
    const handleScroll = () => {
      const navContainer = document.getElementById("navContainer");

      if (window.scrollY > 150) {
        navContainer.classList.add("navBarDark");
      } else {
        navContainer.classList.remove("navBarDark");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header id="navContainer" className="md:sticky top-0 z-10 font-bold bg-inherit flex items-center md:h-10v transition-colors duration-700">
      <div className="container mx-auto flex flex-wrap h-1/2 flex-col md:flex-row items-center justify-between">
        <div className="title-font mb-4 md:mb-0">
          <a href="/" className="ml-3 text-2xl hover:text-blue-300 duration-300">
            HOLLY MILLEA
          </a>
        </div>
        <nav className="md:ml-4 md:py-1 md:pl-4 gap-8	flex flex-wrap items-center text-base justify-center">
          <a href="/about" className="hover:text-blue-300 duration-300">
            about.
          </a>
          <Link to="/#projects" className="hover:text-blue-300 duration-300">
            projects.
          </Link>
          <Link to="/#skills" className="hover:text-blue-300 duration-300">
            skills.
          </Link>
          <Link to="/contact" className="hover:text-blue-300 duration-300">
            contact.
          </Link>
        </nav>
      </div>
    </header>
  );
}