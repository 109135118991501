import React from 'react';
import worksheet from "../images/worksheet.png";
import code from "../images/code.png";
import video from "../images/snake.mp4";

export default function Snake() {
  return (
    <div className="container mx-auto py-10 w-5/6 md:w-4/6 flex flex-col gap-10">
      <a href="/#projects" className="text-3xl mr-auto hover:text-blue-300 text-center md:text-left">⟵</a>
      <h1 className="text-center text-3xl font-bold">A classic game of Snake!</h1>
      <video className='w-5/6 mx-auto' alt='Somebody playing snake' controls autoPlay preload="metadata" src={video}></video>
      <p className='w-5/6 mx-auto'>When teaching at The University of Bristol, I decided to teach my students Python in a fun and creative way.
        <br></br>
        <br></br>
        I built this version of Snake and deconstructed it into mini-tasks so the students could build it themselves.
        <br></br>
        <br></br>
        If you wish to give it a go, you can find the files <a href='https://github.com/hollymillea/Y2Tutorials/tree/main/Week5' target='_blank' rel='noreferrer' className='underline font-bold hover:text-blue-300'>here</a>!
      </p>
      <div className='flex flex-row w-5/6 mx-auto flex-col md:flex-row gap-10 md:gap-0'>
        <img className='md:w-1/2' alt='Worksheet of how to build snake using Python' src={worksheet}></img>
        <img className='md:w-1/2' alt='Example code of Snake built using Python'src={code}></img>
      </div>
    </div>
  );
}