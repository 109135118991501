import React from 'react';
import raytracer from "../images/raytracer.png";
import photon from "../images/photon.png";
import photon2 from "../images/photon2.png";

export default function Raytracer() {
  return (
    <div className="container mx-auto py-10 w-5/6 md:w-4/6 gap-10">
      <a href="/#projects" className="text-3xl mr-auto hover:text-blue-300 text-center md:text-left">⟵</a>
      <h1 className="text-center text-3xl font-bold mb-10">Raytracer / Rasteriser</h1>
      <img src={raytracer} alt='A mirror sphere, a green sphere and a red bunny' className='w-4/6 mb-8 mx-auto'></img>
      <p className="w-5/6 mx-auto mb-8 md:text-left">
        TASK: Render an image of a sphere and a bunny starting from scratch.
        <br></br>
        <br></br>
        Here, you can see some rendered images created from a raytracer I built. I have built a couple raytracers and rasterisers over the years, one using Matlab and one with C++.
        <br></br>
        <br></br>
        Completing these projects are when I learnt the fundmentals of 3D graphics rendering, such as camera setups, lighting, shaders etc!
        <br></br>
        <br></br>
        Below are some images of when I experimented with photon mapping: a simulation of a more realistic lighting model, specifically how light can interact with different objects and reflect between them. Note, the effect of photon mapping has been exaggerated in these images.
      </p>
      <div className='flex gap-10 mb-10'>
        <img src={photon2} className='w-1/2' alt='A blue sphere'></img>
        <img src={photon} className='w-1/2' alt='A blue sphere'></img>
      </div>
      <p className='w-5/6 mx-auto mb-10'>Code for my raytracer (and rasteriser) built using C++ can be found <a className='underline text-bold hover:text-blue-300' target='_blank' rel='noreferrer' href='https://github.com/DMurataj01/COMS3015-Graphics-Rendering-Engine'>here</a>!</p>
      <iframe className='mx-auto w-5/6' width="420" height="315" title='A simple object scene spinning' src="https://www.youtube.com/embed/aDPoczZtAtE"></iframe>
    </div>
  );
}