import React from 'react';
import art from "../images/canvasSketch-old.png";
import art2 from "../images/canvas2.png";
import art3 from "../images/canvas3.png";
import art4 from "../images/happy-to-be-here.png";

export default function Sketches() {
  return (
    <div className="container mx-auto py-10 w-5/6 md:w-4/6 flex flex-col gap-10">
      <a href="/#projects" className="text-3xl hover:text-blue-300 text-center md:text-left mr-auto">⟵</a>
      <h1 className="text-3xl font-bold text-center">Generative Art</h1>
      <p className='text-center'>Upon realising I needed some new art for my flat, I decided to code my own!</p>
      <div className="flex flex-col md:flex-row items-center gap-14 text-center">
        <img src={art} alt='Art print' className='w-full md:w-3/6'></img>
        <p className="mb-8 md:text-left">
            6000 tiny rising suns. Utilising JavaScript's canvas to create the grid and build the layers and perlin noise to create the fluidity and randomness.
            <br></br>
            <br></br>
            Code can be found here: <a className='underline font-bold hover:text-blue-300' rel='noreferrer' href="https://github.com/hollymillea/Sketch" target='_blank'>https://github.com/hollymillea/Sketch</a>
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center gap-10">
        <img src={art2} className='w-full md:w-1/2' alt=''></img>
        <img src={art3} className='w-full md:w-1/2' alt=''></img>
      </div>
      <img src={art4} alt="Words 'happy to be here !' surrounded by smiley faces" className='w-full md:w-3/6 mx-auto'></img>
    </div>
  );
}