import React from 'react';
import disco from "../images/disco.png";
import disco2 from "../images/Holly.mp4";
import dance from "../images/DanceAnimation.mp4";

export default function Disco() {
  return (
    <div className="container flex flex-col items-center text-center gap-10 mx-auto py-10 w-5/6 md:w-4/6">
      <a href="/#projects" className="text-3xl mr-auto hover:text-blue-300 text-center md:text-left">⟵</a>
      <h1 className="text-center text-3xl font-bold">This is Disco Melvin</h1>
      <img src={disco} alt='Cartoon character with a tennis racket for a head.' className='w-5/6'></img>
      <p className="mb-8 w-5/6 text-left">
        Here are a couple of short animations of a little dude called Melvin. Designed, constructed and rendered by me using Maya.
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        Below is an interpretation of how I imagine Melvin being brought to life...
      </p>
      <video src={disco2} className="w-5/6" controls preload="metadata"></video>
      <p className='w-5/6 text-left'>Here: Melvin just having a good time.</p>
      <video src={dance} className="w-5/6" controls preload="metadata"></video>
    </div>
  );
}